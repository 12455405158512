import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorProviders } from './interceptors';
import { NavbarComponent } from './navbar/navbar.component';
import { TopnavModule } from './topnav/topnav.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AtlasBannersModule } from './atlas-ui/banners';
import { AppBannersModule } from './banners/banners.module';
import { PopulationModule } from './population/population.module';
import { TypeformSurveyComponent } from './typeform-survey/typeform-survey.component';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { NotificationWidgetModule } from '@wellsky/ai-notification/notification-widget';
import { CoreModule as AINotificationCoreModule } from '@wellsky/ai-notification/core';
import { AuthService } from './services/auth.service';
import { first, map } from 'rxjs';
import { GlobalStateService } from './services/global-state.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [AppComponent, NavbarComponent, TypeformSurveyComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OktaAuthModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    NgbModule,
    MatIconModule,
    MatMenuModule,
    AtlasBannersModule,
    AppBannersModule,
    PopulationModule,
    CoreModule,
    TopnavModule,
    AINotificationCoreModule.withConfiguration({
      notificationServiceApiBaseUrl: `${environment.apiURL}/notification-service/`,
      authorizationToken$: AuthService.authToken$,
      userId$: AuthService.currentUser$.pipe(
        first(),
        map((user) => user.id)
      ),
    }),
    NotificationWidgetModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDividerModule,
  ],
  providers: [
    HttpInterceptorProviders,
    GlobalStateService,
    {
      provide: OKTA_CONFIG,
      useValue: {
        oktaAuth: new OktaAuth({
          issuer: `${environment.authentication.oktaDomain}/oauth2/${environment.authentication.authServerId}`,
          clientId: environment.authentication.clientId,
          redirectUri: environment.authentication.redirectUri,
          scopes: environment.authentication.scopes,
        }),
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
