<mat-form-field
  class="w-100"
  [appearance]="fieldAppearance"
  [floatLabel]="floatLabel"
  [hideRequiredMarker]="hideRequiredMarker"
  [color]="color"
>
  <!-- label -->
  <span
    #contentLabel
    [hidden]="true"
    (cdkObserveContent)="labelContentChanged()"
  >
    <ng-content select="[label]"></ng-content>
  </span>

  <mat-label *ngIf="label.length">
    {{ label }}
  </mat-label>

  <mat-label #labelContentData *ngIf="contentLabel.children.length"></mat-label>

  <!-- input -->
  <input
    (blur)="fireBlur($event)"
    [readonly]="readonly"
    matInput
    #matInput
    [type]="type"
    [formControl]="inputControl"
    [required]="required"
    [attr.maxLength]="maxLength"
    [placeholder]="placeholder"
    [id]="id"
    [tabIndex]="getTabIndex()"
  />

  <!-- label prefix -->
  <span matPrefix>
    <ng-content select="[input-prefix]"></ng-content>
  </span>

  <!-- label suffix -->
  <span matSuffix class="d-inline-flex">
    <ng-content select="[input-suffix]"></ng-content>
    <button
      *ngIf="showClearButton()"
      type="button"
      mat-icon-button
      (click)="clear($event)"
      [attr.aria-label]="'Clear text'"
      [attr.aria-pressed]="'clear'"
    >
      <mat-icon [color]="color">cancel</mat-icon>
    </button>
  </span>

  <!-- hints -->
  <mat-hint align="start">
    <span *ngIf="hintStart.length">{{ hintStart }}</span>
    <ng-content *ngIf="!hintStart.length" select="[hint-start]"></ng-content>
  </mat-hint>

  <mat-hint align="end">
    <span *ngIf="hintEnd.length">{{ hintEnd }}</span>
    <ng-content *ngIf="!hintEnd.length" select="[hint-end]"></ng-content>
    <span class="ms-1" *ngIf="options['charCounter'] && !disabled">
      {{ currentInputLength }}/{{ maxLength }}
    </span>
  </mat-hint>

  <!-- errors -->
  <mat-error>
    <span>{{ errorMessage }}</span>
    <mat-icon class="float-right">warning</mat-icon>
  </mat-error>
</mat-form-field>
