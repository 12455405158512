import { Component, Input } from '@angular/core';

// Global counter variable for assigning dynamic id
let nextUniqueId = 0;

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[atlas-banner]',
  templateUrl: './banner.component.html',
  exportAs: 'atlasBanner',
})
export class AtlasBannerComponent {
  private _uid = `atlas-banner-${nextUniqueId++}`;
  private _id: string;

  /**
   * Set Id for the banner
   */
  @Input()
  set id(value: string) {
    // If id is passed from external component then assign orelse assign id from within the component
    this._id = value || this._uid;
  }
  get id(): string {
    return this._id;
  }

  constructor() {
    // Force setter to be called in case id was not specified.
    this.id = this.id;
  }
}
