<div atlas-banner data-test-id="error-banner">
  <div atlas-banner-image>
    <mat-icon class="error-icon">error</mat-icon>
  </div>
  <div atlas-banner-content>
    <p class="m-0 mat-body mat-error">
      {{ data.message }}
    </p>
  </div>
  <div atlas-banner-actions>
    <button
      mat-button
      color="primary"
      class="text-uppercase mr-2"
      (click)="dismiss()"
    >
      Dismiss
    </button>
  </div>
</div>
