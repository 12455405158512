<div class="organizational-unit-search-filter">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-listbox>
    <mat-chip
      *ngFor="let selection of selections"
      class="mat-standard-chip"
      [removable]="
        selections.length > 1 || selection.value !== (userOu | async)?.id
      "
      (removed)="removeOrganizationalUnit(selection.value)"
    >
      {{ selection.label }}
      <button
        matChipRemove
        *ngIf="
          selections.length > 1 || selection.value !== (userOu | async)?.id
        "
      >
        <mat-icon> cancel </mat-icon>
      </button>
    </mat-chip>
    <button
      mat-icon-button
      (click)="addOrganizationalUnit()"
      [disabled]="disabled"
    >
      <mat-icon class="add-ou-icon">add</mat-icon>
    </button>
  </mat-chip-listbox>
</div>
