import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AtlasBannerService } from '../atlas-ui/banners';
import { ApiErrorBannerComponent } from '../banners/api-error-banner/api-error-banner.component';
import { Injectable } from '@angular/core';
import { getDisplayError } from '../utils/http.utils';

// TODO: This "hack" won't be needed in Angular 12, shipping May 2021.
//  See https://github.com/angular/angular/pull/25751
export const DISABLE_ERROR_BANNER_HEADER = 'x-ws-disable-error-banner';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private bannerService: AtlasBannerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get(DISABLE_ERROR_BANNER_HEADER)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = getDisplayError(error);
        if (errorMessage && !this.bannerService.isBannerLoaded) {
          this.bannerService.open(ApiErrorBannerComponent, {
            data: { message: errorMessage },
          });
        }
        return throwError(error);
      })
    );
  }
}
