import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtlasBannerComponent } from './banner.component';
import { AtlasBannerContainer } from './banner-container.component';
import { AtlasBannerService } from './banner.service';
import { PortalModule } from '@angular/cdk/portal';
import { AtlasBannerConfig } from './banner-config';

@NgModule({
  declarations: [AtlasBannerComponent, AtlasBannerContainer],
  imports: [CommonModule, PortalModule],
  exports: [AtlasBannerComponent, AtlasBannerContainer],
  providers: [AtlasBannerService, AtlasBannerConfig],
})
export class AtlasBannersModule {}
