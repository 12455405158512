import { ApiErrorBannerComponent } from './api-error-banner/api-error-banner.component';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AtlasBannersModule } from '../atlas-ui/banners';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ApiErrorBannerComponent],
  imports: [MatIconModule, AtlasBannersModule, MatButtonModule],
  exports: [ApiErrorBannerComponent],
})
export class AppBannersModule {}
