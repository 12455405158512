import {
  Injectable,
  OnDestroy,
  Injector,
  StaticProvider,
  InjectionToken,
  Optional,
  Inject,
} from '@angular/core';
import { Portal, ComponentPortal } from '@angular/cdk/portal';
import { Subject, Observable } from 'rxjs';
import { AtlasBannerRef } from './banner-ref';
import { AtlasBannerServiceInterface } from './banner-service-interface';
import { AtlasBannerConfig } from './banner-config';

/** Injection token that can be used to access the data that was passed in to a Banner. */
export const ATLAS_BANNER_DATA = new InjectionToken<any>('AtlasBannerData');

/** Injection token that can be used to specify default Banner options. */
export const ATLAS_BANNER_DEFAULT_OPTIONS = new InjectionToken<AtlasBannerConfig>(
  'atlas-banner-default-options'
);

@Injectable()
export class AtlasBannerService
  implements AtlasBannerServiceInterface, OnDestroy {
  /** Subject for notifying the user that the banner has opened Portal. */
  private bannerPortalSubject$: Subject<Portal<any> | null>;
  public isBannerLoaded: boolean;

  constructor(
    private injector: Injector,
    @Optional()
    @Inject(ATLAS_BANNER_DEFAULT_OPTIONS)
    private _defaultOptions: AtlasBannerConfig
  ) {
    this.bannerPortalSubject$ = new Subject();
    this.isBannerLoaded = false;
  }

  /**
   * create injector
   * @param bannerRef
   * @param config: optional
   * @Output Injector
   */
  private createInjector(bannerRef: any, config?: AtlasBannerConfig): Injector {
    const provider: StaticProvider[] = [
      { provide: ATLAS_BANNER_DATA, useValue: config ? config.data : null },
      { provide: AtlasBannerRef, useValue: bannerRef },
    ];

    return Injector.create({ providers: provider, parent: this.injector });
  }

  /**
   * Open Banner
   * @param: component to be opened
   */
  public open(
    component: any,
    config?: AtlasBannerConfig
  ): AtlasBannerRef<undefined> {
    config = this.applyConfigDefaults(
      config,
      this._defaultOptions || new AtlasBannerConfig()
    );

    const bannerRef = new AtlasBannerRef(this.injector.get(AtlasBannerService));

    /* To check whether banner is already opened in banner container
     * If False, then attach banner content to the container
     * If True, then throw error
     */
    if (!this.isBannerLoaded) {
      this.isBannerLoaded = true;
      this.attachBannerContent(component, bannerRef, config);
    } else {
      throw Error(
        'Atlas.UI Banner: Banner already present. Dismiss the existing one to open new.'
      );
    }
    return bannerRef;
  }

  /**
   * Applies default options to the banner config.
   * @param config Config to be modified.
   * @param defaultOptions Default options provided.
   * @returns The new configuration object.
   */
  private applyConfigDefaults(
    config?: AtlasBannerConfig,
    defaultOptions?: AtlasBannerConfig
  ): AtlasBannerConfig {
    return { ...defaultOptions, ...config };
  }

  public get getBannerPortal(): Observable<Portal<any> | null> {
    return this.bannerPortalSubject$.asObservable();
  }

  /**
   * Emit banner content to container
   */
  private attachBannerContent(
    component: any,
    bannerRef: AtlasBannerRef<any>,
    config?: AtlasBannerConfig
  ): void {
    const injector = this.createInjector(bannerRef, config);
    this.bannerPortalSubject$.next(
      new ComponentPortal(component, null, injector)
    );
  }

  /*
   * Emit null to detach the content from container when close is called
   */
  public detachBannerContent(): void {
    this.isBannerLoaded = false;
    this.bannerPortalSubject$.next(null);
  }

  ngOnDestroy(): void {
    this.bannerPortalSubject$.complete();
  }
}
