import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { AtlasTooltip } from './tooltip.directive';

@NgModule({
  declarations: [AtlasTooltip],
  imports: [CommonModule, OverlayModule],
  exports: [AtlasTooltip],
})
export class AtlasTooltipModule {}
