<div class="filter-chips">
  <!-- Horizontal -->
  <mat-chip-listbox
    class="horizontal-chip-list"
    [selectable]="false"
    data-test-id="horizontal-filter-chips"
  >
    <ng-container *ngFor="let chip of chips">
      <mat-chip
        class="mat-standard-chip"
        [removable]="chip.removable"
        (removed)="chip.removeFilter()"
      >
        {{ chip.label }}: {{ chip.displayValue }}
        <button matChipRemove *ngIf="chip.removable">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </ng-container>
  </mat-chip-listbox>

  <!-- Ellipsis dropdown -->
  <button
    mat-icon-button
    *ngIf="chips.length > 0"
    [matMenuTriggerFor]="chipsDropdown"
    class="dropdown-trigger-button"
    data-test-id="filter-chip-dropdown-button"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #chipsDropdown="matMenu">
    <ng-template matMenuContent>
      <div class="dropdown-title">CURRENT SELECTIONS</div>
      <div class="dropdown-content">
        <div class="dropdown-chip-list">
          <mat-chip-listbox
            class="mat-chip-list-stacked"
            [selectable]="false"
            aria-orientation="vertical"
            data-test-id="dropdown-filter-chips"
          >
            <ng-container *ngFor="let chip of chips">
              <mat-chip
                class="mat-standard-chip"
                [removable]="chip.removable"
                (removed)="chip.removeFilter()"
              >
                {{ chip.label }}: {{ chip.displayValue }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </ng-container>
          </mat-chip-listbox>
        </div>
        <button
          mat-button
          color="warn"
          class="dropdown-clear-button"
          (click)="removeAllFilters()"
        >
          Clear All Filters
        </button>
      </div>
    </ng-template>
  </mat-menu>
</div>
