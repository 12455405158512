import {
  ElementRef,
  Directive,
  Input,
  ViewContainerRef,
  NgZone,
  Inject,
  Optional,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  MatTooltip,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltipDefaultOptions,
  TooltipPosition,
} from '@angular/material/tooltip';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
@Directive({
  selector: '[atlasTooltip]',
  exportAs: 'atlasTooltip',
})
export class AtlasTooltip extends MatTooltip {
  /** The message to be displayed in the tooltip */
  @Input()
  get atlasTooltip() {
    return this.message;
  }
  set atlasTooltip(message: string) {
    this.message = message;
  }

  /** Allows the user to define the position of the tooltip */
  @Input()
  get tooltipPosition() {
    return this.position;
  }
  set tooltipPosition(value: TooltipPosition) {
    this.position = value;
  }

  /** Disabled the display of the tooltip. */
  @Input()
  get tooltipDisabled() {
    return this.disabled;
  }
  set tooltipDisabled(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /** The delay in ms before showing the tooltip */
  @Input()
  get tooltipShowDelay() {
    return this.showDelay;
  }
  set tooltipShowDelay(delay: number) {
    this.showDelay = delay;
  }

  /** The delay in ms after hiding the tooltip */
  @Input()
  get tooltipHideDelay() {
    return this.hideDelay;
  }
  set tooltipHideDelay(delay: number) {
    this.hideDelay = delay;
  }

  /** Classes to be passed to the tooltip. Supports the same syntax as `ngClass`. */
  @Input()
  get tooltipCSSClass() {
    return this.tooltipClass;
  }
  set tooltipCSSClass(
    value: string | string[] | Set<string> | { [key: string]: any }
  ) {
    this.tooltipClass = value;
  }

  constructor(
    overlay: Overlay,
    elementRef: ElementRef,
    scrollDispatcher: ScrollDispatcher,
    viewContainerRef: ViewContainerRef,
    ngZone: NgZone,
    platform: Platform,
    ariaDescriber: AriaDescriber,
    focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() dir: Directionality,
    @Optional()
    @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
    defaultOptions: MatTooltipDefaultOptions,
    @Inject(DOCUMENT) document: any
  ) {
    super(
      overlay,
      elementRef,
      scrollDispatcher,
      viewContainerRef,
      ngZone,
      platform,
      ariaDescriber,
      focusMonitor,
      scrollStrategy,
      dir,
      defaultOptions,
      document
    );

    /** Applied default css class to the tooltip */
    this.tooltipCSSClass = 'atlas-tooltip';
  }
}
