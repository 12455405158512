import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OrganizationalUnitService } from '../services/organizational-unit.service';
import { AuthService } from '../../services/auth.service';
import {
  debounceTime,
  filter,
  first,
  map,
  pairwise,
  takeWhile,
  throttleTime,
  flatMap,
  switchMap,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject, of } from 'rxjs';
import {
  getParentPath,
  OrganizationalUnit,
  OrganizationalUnitType,
} from '../../models/OrganizationalUnit';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IncrementalLoadingBaseDatasource } from '../incremental-loading-base.datasource';
import {
  ApiSortDirection,
  emptyPaginatedResponse,
} from '../../models/Pagination';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelectionList } from '@angular/material/list';
import { AtlasDialogRef } from 'src/app/atlas-ui/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-organizational-unit-selector',
  templateUrl: './organizational-unit-selector.component.html',
  styleUrls: ['./organizational-unit-selector.component.scss'],
})
export class OrganizationalUnitSelectorComponent implements OnInit, OnDestroy {
  LIST_ITEM_HEIGHT = 48;

  @Input()
  submitLabel: string = 'Select';

  @Input()
  title: string = 'Select an Organizational Unit';

  // E.g., 'cannot <verb> <organizational unit name>'
  @Input()
  verb: string = 'select';

  @Input()
  initialOrganizationalUnitId?: string;

  @Input()
  allowedTypes?: OrganizationalUnitType[];

  /*
  Paths cannot be selected or "drilled down" into. These paths will appear disabled in the UI.
   */
  @Input()
  disabledPaths?: Array<string | RegExp>;

  @Input()
  allowAllOrganizations?: boolean;

  @Output()
  organizationalUnitSelected = new EventEmitter<OrganizationalUnit>();

  @ViewChild('scroller')
  set scroller(el: CdkVirtualScrollViewport | undefined) {
    el?.elementScrolled()
      .pipe(
        untilDestroyed(this),
        map(() => el.measureScrollOffset('bottom')),
        pairwise(),
        filter(([y1, y2]) => y2 < y1 && y2 < this.LIST_ITEM_HEIGHT),
        throttleTime(300)
      )
      .subscribe(() => {
        this.ngZone.run(this.dataSource.fetchMore);
      });
  }

  @ViewChild('ouList') ouList?: MatSelectionList;

  organizationalUnitId?: number;
  organizationalUnit$: Subject<OrganizationalUnit | null> = new BehaviorSubject<OrganizationalUnit | null>(
    null
  );
  dataSource: IncrementalLoadingBaseDatasource<OrganizationalUnit>;

  searchMode: boolean = false;
  searchForm: FormGroup;

  constructor(
    public authService: AuthService,
    private ouService: OrganizationalUnitService,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private dialogRef?: AtlasDialogRef<OrganizationalUnitSelectorComponent>
  ) {
    this.searchForm = this.formBuilder.group({
      search: [''],
    });

    this.dataSource = new IncrementalLoadingBaseDatasource<OrganizationalUnit>(
      (pageNumber) => {
        if (this.searchMode) {
          if (!this.searchForm.value.search) {
            return of(emptyPaginatedResponse<OrganizationalUnit>());
          }
          return this.authService.currentUser$
            .pipe(takeWhile((u) => !u.metadata, true))
            .pipe(
              switchMap((user) =>
                this.ouService.getList({
                  parentId: user.metadata!.organizationalUnitId,
                  page: pageNumber,
                  pageSize: 40,
                  sortBy: 'name',
                  sortDirection: ApiSortDirection.Ascending,
                  search: this.searchForm.value.search,
                  recursive: true,
                  allowAllOrganizations: this.allowAllOrganizations,
                })
              )
            );
        } else {
          if (!this.organizationalUnitId) {
            throw new Error('no org id');
          }
          return this.ouService.getList({
            parentId: this.organizationalUnitId,
            page: pageNumber,
            pageSize: 40,
            sortBy: 'name',
            sortDirection: ApiSortDirection.Ascending,
          });
        }
      }
    );
  }

  ngOnInit(): void {
    this.searchForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(600))
      .subscribe(() => {
        this.dataSource.reset();
      });

    if (this.organizationalUnitId) {
      this.dataSource.reset();
    } else {
      this.authService.currentUser$
        .pipe(takeWhile((u) => !u.metadata, true))
        .subscribe((user) => {
          if (user.metadata) {
            this.organizationalUnitId = user.metadata!.organizationalUnitId;
            this.dataSource.reset();
          }
        });
    }
    this.loadCurrentOrganizationalUnit();
  }

  ngOnDestroy() {
    this.dataSource.disconnect();
  }

  submit = () => {
    const selectedOu = this.getSelectedOrganizationalUnit();
    if (!selectedOu) {
      throw new Error('no OU selected');
    }
    this.dialogRef?.close();
    this.organizationalUnitSelected.emit(selectedOu);
  };

  levelUp = () => {
    this.organizationalUnit$.pipe(first()).subscribe((ou) => {
      if (!ou?.parentId) {
        throw new Error('OU has no parent to level up to');
      }
      this.changeOrganizationalUnitId(ou.parentId);
    });
  };

  drillDown = (nextOrganizationalUnit: OrganizationalUnit) => {
    this.changeOrganizationalUnitId(nextOrganizationalUnit.id);
  };

  canLevelUp$: Observable<boolean> = combineLatest([
    this.authService.currentUser$,
    this.organizationalUnit$,
  ]).pipe(
    untilDestroyed(this),
    map(([authUser, organizationalUnit]) => {
      if (!organizationalUnit) {
        return false;
      }
      const parentPath = getParentPath(organizationalUnit);
      return (
        !!parentPath && authUser.canAccessOrganizationalUnitAtPath(parentPath)
      );
    })
  );

  ouListComparator = (a: OrganizationalUnit, b: OrganizationalUnit): number => {
    const canSelectA = this.canSelectOrganizationalUnit(a);
    const canSelectB = this.canSelectOrganizationalUnit(b);
    if (canSelectA && !canSelectB) {
      return -1;
    }
    if (!canSelectA && canSelectB) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  };

  canSelectOrganizationalUnit = (ou: OrganizationalUnit): boolean => {
    if (this.allowedTypes && !this.allowedTypes.includes(ou.type)) {
      return false;
    }
    if (this.disabledPaths) {
      for (const p of this.disabledPaths) {
        if (typeof p === 'string') {
          if (p === ou.path) {
            return false;
          }
        } else {
          // p is a RegExp
          if (p.test(ou.path)) {
            return false;
          }
        }
      }
    }
    return true;
  };

  canSubmit = () => {
    const selectedOu = this.getSelectedOrganizationalUnit();
    if (!selectedOu) {
      return false;
    }
    return this.canSelectOrganizationalUnit(selectedOu);
  };

  public toggleSearchMode() {
    this.searchMode = !this.searchMode;
    this.dataSource.reset();
  }

  public getSelectedOrganizationalUnit = () =>
    this.ouList?.selectedOptions?.selected?.[0]?.value as OrganizationalUnit;

  private changeOrganizationalUnitId = (id: number) => {
    this.organizationalUnitId = id;
    this.ouList?.deselectAll();
    this.dataSource.reset();
    this.loadCurrentOrganizationalUnit();
  };

  private loadCurrentOrganizationalUnit = () => {
    if (this.organizationalUnitId) {
      this.ouService.getById(this.organizationalUnitId).subscribe((ou) => {
        this.organizationalUnit$.next(ou.result);
      });
    }
  };
}
