import { Component, OnInit } from '@angular/core';
import { UserRole } from '../models/AuthorizedUser';
import { AuthService } from '../services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { FlagKey } from '../models/Flags';
import { FlagsService } from '../services/flags.service';
import { Observable } from 'rxjs';
import { NotificationDto } from '@wellsky/ai-notification/core/src/models/NotificationDto';

@UntilDestroy()
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  userIsLoggedIn: boolean = false;
  hasAdminRole: boolean = false;
  hasUserRole: boolean = false;
  showAlertsWidget$: Observable<boolean>;

  constructor(
    public userService: AuthService,
    public router: Router,
    private flagrService: FlagsService
  ) {
    this.showAlertsWidget$ = flagrService.booleanFlagValue(
      FlagKey.EnableAlerts
    );
  }

  ngOnInit() {
    this.userService.currentUser$.pipe(untilDestroyed(this)).subscribe((u) => {
      this.userIsLoggedIn = !!u?.metadata;
      this.hasAdminRole = u?.hasRole(UserRole.Admin);
      this.hasUserRole = u?.hasRole(UserRole.User);
    });
  }

  public login() {
    this.userService.login();
  }

  public logout() {
    this.userService.logout();
  }

  public handleNotificationSelection({ referenceKey }: NotificationDto) {
    window.open(`/patient/${referenceKey}/notifications`);
  }

  public isActive(route: string): boolean {
    return this.router.isActive(route, false);
  }

  public getTabIndicatorClass(route: string): string {
    return this.isActive(route) ? 'active-tab-indicator' : '';
  }
}
