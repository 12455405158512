import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  exportAs: 'topnav',
})
export class TopnavComponent implements AfterViewChecked {
  @ViewChild('topnavIcon')
  private topnavIcon!: ElementRef<HTMLElement>;

  private topnavIconLength: number;

  topnavIconPresent: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    this.topnavIconLength = 0;
    this.topnavIconPresent = false;
  }

  ngAfterViewChecked() {
    const elemChildrenLength = this.topnavIcon.nativeElement.children.length;

    if (this.topnavIconLength === elemChildrenLength) {
      return;
    }

    this.topnavIconLength = elemChildrenLength;

    if (this.topnavIconLength > 0) {
      this.topnavIconPresent = true;
    } else {
      this.topnavIconPresent = false;
    }

    this.cdr.detectChanges();
  }
}
