import { Injectable } from '@angular/core';

/**
 * Configuration for loading banner with the AtlasBanner service.
 */
@Injectable()
export class AtlasBannerConfig<D = any> {
  /** Data being injected into the child component. */
  data?: D | null = null;
}
