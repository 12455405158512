import { Component, Input, OnInit } from '@angular/core';
import { AtlasDialog } from 'src/app/atlas-ui/dialog';
import { AuthService } from '../../../../services/auth.service';
import { OrganizationalUnit } from '../../../../models/OrganizationalUnit';
import { first, map, switchMap } from 'rxjs/operators';
import { OrganizationalUnitSelectorComponent } from '../../../organizational-unit-selector/organizational-unit-selector.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthorizedUser } from '../../../../models/AuthorizedUser';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { firstValueFrom, isObservable, Observable, of, Subject } from 'rxjs';
import { SelectOption } from '@wellsky/ai-dataview-ui/filters';

@Component({
  selector: 'organizational-unit-search',
  templateUrl: './organizational-unit-search.component.html',
  styleUrls: ['./organizational-unit-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OrganizationalUnitSearchComponent,
    },
  ],
})
export class OrganizationalUnitSearchComponent
  implements OnInit, ControlValueAccessor {
  constructor(public authService: AuthService, public dialog: AtlasDialog) {
    this.userOu = firstValueFrom(
      authService.currentUser$.pipe(
        switchMap((u) => u.metadata.organizationalUnit$),
        first()
      )
    );
  }

  @Input() label?: string;

  @Input() appearance?: MatFormFieldAppearance;

  onChange = (organizationalUnits: SelectOption[]) => {};

  onTouched = () => {};

  disabled = false;

  selections: SelectOption[] = [];

  private writes$ = new Subject<SelectOption>();

  public userOu: Promise<OrganizationalUnit>;

  ngOnInit() {
    this.writes$
      .pipe(
        switchMap((observableOrValue) =>
          isObservable(observableOrValue)
            ? observableOrValue
            : of(observableOrValue)
        ),
        map((singleOrArray) =>
          Array.isArray(singleOrArray) ? singleOrArray : [singleOrArray]
        )
      )
      .subscribe((selections) => {
        this.selections = selections;
        this.sendChange();
      });
  }

  removeOrganizationalUnit = async (organizationalUnitId: number | string) => {
    const userOu = await this.userOu;

    const newOrganizationalUnits = [...this.selections];
    const idx = newOrganizationalUnits.findIndex(
      (ou) => ou.value === organizationalUnitId
    );
    if (idx > -1) {
      newOrganizationalUnits.splice(idx, 1);
    }
    this.selections = newOrganizationalUnits;
    this.sendChange();
    this.onTouched();
  };

  addOrganizationalUnit = () => {
    this.authService.currentUser$
      .pipe(first())
      .subscribe((currentUser: AuthorizedUser) => {
        const selectorDialog = this.dialog.open<
          OrganizationalUnitSelectorComponent,
          OrganizationalUnitSelectorComponent
        >(OrganizationalUnitSelectorComponent);
        selectorDialog.componentInstance.organizationalUnitId = currentUser.metadata!.organizationalUnitId;
        selectorDialog.componentInstance.organizationalUnitSelected
          .pipe(first())
          .subscribe((selectedOu: OrganizationalUnit) => {
            // If an OU was chosen that isn't selected yet, add it
            if (!this.selections.some((s) => s.value === selectedOu.id)) {
              this.selections = [
                ...this.selections,
                { value: selectedOu.id, label: selectedOu.name },
              ];
              this.sendChange();
            }
            this.onTouched();
          });
      });
  };

  sendChange() {
    this.onChange?.(this.selections);
  }

  // NgValueAccessor interface below this point
  writeValue(value: undefined | SelectOption) {
    // Handle null or undefined default form values
    if (!value) {
      return;
    }

    this.writes$.next(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
