import { Component } from '@angular/core';
import { AuthorizedUser } from './models/AuthorizedUser';
import { AuthService } from './services/auth.service';
import { AtlasBannerService } from './atlas-ui/banners';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public bannerService: AtlasBannerService,
    public authService: AuthService
  ) {}
}
