import { NgModule } from '@angular/core';
import { PopulationRoutingModule } from './population-routing.module';
import { HospitalizationRiskChartCardComponent } from './chart-cards/hospitalization-risk-chart-card/hospitalization-risk-chart-card.component';
import { MatIconModule } from '@angular/material/icon';
import { LupaRiskChartCardComponent } from './chart-cards/lupa-risk-chart-card/lupa-risk-chart-card.component';
import { VisitUtilizationChartCardComponent } from './chart-cards/visit-utilization-chart-card/visit-utilization-chart-card.component';
import { MatTableModule } from '@angular/material/table';
import { PopulationComponent } from './population.component';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PopulationService } from './population.service';
import { ComorbidityCellComponent } from './cells/comorbidity-cell/comorbidity-cell.component';
import { LupaRiskCellComponent } from './cells/lupa-risk-cell/lupa-risk-cell.component';
import { VisitUtilizationCellComponent } from './cells/visit-utilization-cell/visit-utilization-cell.component';
import { HospitalizationRiskCellComponent } from './cells/hospitalization-risk-cell/hospitalization-risk-cell.component';
import { HospiceSuitabilityCellComponent } from './cells/hospice-suitability-cell/hospice-suitability-cell.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AdlImprovementCellComponent } from './cells/adl-improvement-cell/adl-improvement-cell.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FilterDrawerComponent } from './filters/filter-drawer/filter-drawer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchDropdownComponent } from './filters/search-dropdown/search-dropdown.component';
import { FilterChipsComponent } from './filters/filter-chips/filter-chips.component';
import { MatMenuModule } from '@angular/material/menu';
import { RangeInputComponent } from './filters/range-input/range-input.component';
import { CoreModule } from '../core/core.module';
import { AlertsCellComponent } from './cells/alerts-cell/alerts-cell.component';
import { NotificationWidgetModule } from '@wellsky/ai-notification/notification-widget';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AtlasTooltipModule } from '../atlas-ui/tooltip';

@NgModule({
  providers: [PopulationService],
  declarations: [
    HospitalizationRiskChartCardComponent,
    LupaRiskChartCardComponent,
    VisitUtilizationChartCardComponent,
    PopulationComponent,
    ComorbidityCellComponent,
    LupaRiskCellComponent,
    VisitUtilizationCellComponent,
    HospitalizationRiskCellComponent,
    HospiceSuitabilityCellComponent,
    AdlImprovementCellComponent,
    FilterDrawerComponent,
    SearchDropdownComponent,
    FilterChipsComponent,
    RangeInputComponent,
    AlertsCellComponent,
  ],
  imports: [
    CommonModule,
    PopulationRoutingModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AtlasTooltipModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    CoreModule,
    NotificationWidgetModule,
    MatButtonModule,
    MatChipsModule,
    MatCardModule,
    MatProgressSpinnerModule,
  ],
  exports: [],
})
export class PopulationModule {}
