import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { PopulationDatasource } from './population.datasource';
import { MatSort } from '@angular/material/sort';
import { PopulationFilters, PopulationService } from './population.service';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from '../services/auth.service';
import {
  combineLatestWith,
  debounceTime,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ApiSortDirection } from '../models/Pagination';
import {
  combineLatest,
  EMPTY,
  firstValueFrom,
  fromEvent,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HospitalizationRiskStratification } from '../models/HospitalizationRisk';
import { FlagsService } from '../services/flags.service';
import { FlagKey } from '../models/Flags';
import { LupaRiskChartSelection } from './chart-cards/lupa-risk-chart-card/lupa-risk-chart-card.component';
import { VisitUtilizationChartSelection } from './chart-cards/visit-utilization-chart-card/visit-utilization-chart-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DataGridFiltersService,
  DataViewFiltersProvider,
} from '@wellsky/ai-dataview-ui/filters';
import {
  PopulationFilterFieldsFactory,
  PopulationGridColumns,
} from './population.grid';
import { FilterOptionsService } from './filters/filter-options.service';
import { GlobalStateService } from '../services/global-state.service';
import {
  DataViewGridProvider,
  GridService,
  SortDirection,
} from '@wellsky/ai-dataview-ui/grid';

interface PopulationViewColumn {
  name: string;
  priority: number;
  minWidth: number;
  maxWidth?: number;
  displayOrder: number;
  visible?: Observable<boolean>;
}

const COLUMN_SPACING = 16;

@UntilDestroy()
@Component({
  selector: 'app-population',
  templateUrl: './population.component.html',
  styleUrls: ['./population.component.scss'],
  providers: [
    DataViewFiltersProvider({
      deps: [FilterOptionsService],
      fieldsFactory: PopulationFilterFieldsFactory,
      options: { sticky: true },
    }),
    DataViewGridProvider({
      columns: PopulationGridColumns,
      options: {
        initialSortColumnId: 'hospitalizationRiskScaled',
        initialSortDirection: SortDirection.Descending,
        sticky: true,
      },
    }),
  ],
})
export class PopulationComponent implements AfterViewInit {
  displayedColumns: string[] = [];

  @ViewChild('sortPatients', { static: true }) sort: MatSort | null = null;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator | null = null;

  @ViewChild('tableContainer')
  tableContainer: ElementRef<HTMLDivElement> | null = null;

  readonly columns: Record<string, Omit<PopulationViewColumn, 'name'>>;
  private readonly prioritizedColumns: PopulationViewColumn[];

  dataSource: PopulationDatasource;

  filters$ = new ReplaySubject<PopulationFilters>(1);

  showTiles$: Observable<boolean>;
  showFilters$: Observable<boolean>;
  allowPatientNavigation$: Observable<boolean>;
  hasCSVDownloaded?: boolean = true;
  showAlertsWidget$: Observable<boolean>;

  constructor(
    private flagsService: FlagsService,
    private populationService: PopulationService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private filtersService: DataGridFiltersService,
    public gridService: GridService,
    private globalStateService: GlobalStateService
  ) {
    const defaultOrganizationalUnit$ = authService.currentUser$.pipe(
      switchMap((u) => u.metadata.organizationalUnit$)
    );
    this.dataSource = new PopulationDatasource(
      populationService,
      changeDetectorRefs
    );

    // Keep track of filter string, to allow routing to population view and maintaining filters
    this.activatedRoute.queryParamMap.subscribe((q) => {
      const filterString = q.get('filter');
      const gridString = q.get('grid');
      if (filterString) {
        globalStateService.populationFilterString = filterString;
      }
      if (gridString) {
        globalStateService.populationGridString = gridString;
      }
    });

    this.filtersService.valueChanges
      .pipe(
        untilDestroyed(this),
        combineLatestWith(defaultOrganizationalUnit$),
        switchMap(([value, defaultOrganizationalUnit]) => {
          // If only the user's OU is currently selected, replace it with the newly chosen OU
          if (
            value.organizationalUnitIDs.length === 2 &&
            value.organizationalUnitIDs[0] === defaultOrganizationalUnit.id
          ) {
            this.filtersService.formGroup.patchValue({
              organizationalUnitIDs: [
                this.filtersService.formGroup.value.organizationalUnitIDs[1],
              ],
            });
            return EMPTY;
            // If we just removed the last organizational unit, add the user's OU back as the default
          } else if (value.organizationalUnitIDs.length === 0) {
            this.filtersService.formGroup.patchValue({
              organizationalUnitIDs: [
                {
                  value: defaultOrganizationalUnit.id,
                  label: defaultOrganizationalUnit.name,
                },
              ],
            });
            return EMPTY;
          } else {
            return of(value);
          }
        }),
        debounceTime(300),
        filter(() => this.filtersService.formGroup.valid),
        // Translate caregivers into form expected by API
        map((filters) => ({
          ...filters,
          caseManagers: filters.caseManagers?.map((cm: string) => {
            const [lastName, firstName] = cm.split(', ');
            return {
              lastName,
              firstName,
            };
          }),
          // Translate referring physicians into form expected by API
          referringPhysicians: filters.referringPhysicians?.map(
            (rf: string) => {
              const [lastName, firstName] = rf.split(', ');
              return {
                lastName,
                firstName,
              };
            }
          ),
        }))
      )
      .subscribe((value) => {
        this.filters$.next(value);
        this.reload();
      });

    // Reload data when sort changes
    this.gridService.sort$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.loadListPage());

    this.showTiles$ = flagsService.booleanFlagValue(
      FlagKey.ShowPopulationViewTiles
    );
    this.showFilters$ = flagsService.booleanFlagValue(
      FlagKey.ShowPopulationViewFilters
    );
    this.allowPatientNavigation$ = flagsService.booleanFlagValue(
      FlagKey.AllowPopulationViewPatientNavigation
    );
    this.showAlertsWidget$ = flagsService.booleanFlagValue(
      FlagKey.EnableAlerts
    );

    this.columns = {
      alerts: {
        priority: 800,
        displayOrder: 100,
        minWidth: 56,
        visible: this.showAlertsWidget$,
      },
      lastName: { priority: 100, displayOrder: 200, minWidth: 60 },
      firstName: { priority: 1400, displayOrder: 300, minWidth: 60 },
      hospitalizationRisk: { priority: 200, displayOrder: 400, minWidth: 103 },
      primaryDx: { priority: 1100, displayOrder: 500, minWidth: 140 },
      comorbidities: { priority: 1200, displayOrder: 600, minWidth: 83 },
      hospiceSuitability: { priority: 300, displayOrder: 700, minWidth: 108 },
      adlImprovement: {
        priority: 900,
        displayOrder: 800,
        minWidth: 77,
        maxWidth: 95,
      },
      plannedVisits: {
        priority: 600,
        displayOrder: 900,
        minWidth: 90,
        maxWidth: 90,
        visible: flagsService
          .booleanFlagValue(FlagKey.ShowPopulationViewVirtualVisits)
          .pipe(map((v) => !v)),
      },
      plannedInPersonVisits: {
        priority: 600,
        displayOrder: 1000,
        minWidth: 90,
        maxWidth: 90,
        visible: flagsService.booleanFlagValue(
          FlagKey.ShowPopulationViewVirtualVisits
        ),
      },
      utilizationAssessment: {
        priority: 500,
        displayOrder: 1100,
        minWidth: 71,
        maxWidth: 85,
      },
      plannedVirtualVisits: {
        priority: 1300,
        displayOrder: 1200,
        minWidth: 90,
        maxWidth: 90,
        visible: flagsService.booleanFlagValue(
          FlagKey.ShowPopulationViewVirtualVisits
        ),
      },
      lupaRisk: {
        priority: 400,
        displayOrder: 1300,
        minWidth: 100,
        maxWidth: 110,
      },
    };
    this.prioritizedColumns = Object.entries(this.columns)
      .map(([name, meatadata]) => ({
        ...meatadata,
        name,
      }))
      .sort((a, b) => a.priority - b.priority);
  }

  ngAfterViewInit() {
    this.paginator?.page.pipe(tap(() => this.loadListPage())).subscribe();
    this.sort?.sortChange
      .pipe(
        tap(({ active, direction }) =>
          this.gridService.setSort(active, direction as SortDirection)
        )
      )
      .subscribe();

    // Subscribe to window resize events so we can alter display columns as needed
    const windowSizeChanged$ = fromEvent(window, 'resize').pipe(
      untilDestroyed(this),
      debounceTime(100),
      startWith(this.tableContainer?.nativeElement.clientWidth)
    );

    // Combine all sources that determine which columns to show.
    combineLatest([
      // Screen size
      windowSizeChanged$,
      // Individual column visibility (such as flags)
      ...this.prioritizedColumns.map((c) => c.visible).filter((v) => !!v),
    ]).subscribe(() => {
      // Defer to next tick to avoid changing displayedColumns expression in middle of view rendering
      setTimeout(() => this.updateDisplayedColumns());
    });
  }

  reload() {
    if (this.paginator?.pageIndex === 0) {
      this.loadListPage();
    } else {
      this.paginator?.firstPage();
    }
    this.dataSource = this.dataSource;
  }

  loadListPage = async () => {
    const filters = await firstValueFrom(this.filters$);
    const sortDirection = this.sort?.direction || ApiSortDirection.Ascending;
    // The organizational unit ID will take a moment to load, wait until it's available.
    if (filters.organizationalUnitIDs?.length) {
      this.dataSource.loadList(filters, {
        // Paginator is 0-indexed, but our API uses 1-indexed pages
        page: (this.paginator?.pageIndex ?? 0) + 1,
        pageSize: this.paginator?.pageSize ?? 20,
        sortBy: this.sort?.active,
        sortDirection: sortDirection as ApiSortDirection,
      });
      this.changeDetectorRefs.detectChanges();
    }
  };

  public getDiagnosisText = (
    code: string,
    description: string | undefined
  ): string => {
    let text = code;
    if (description?.length) {
      text += `: ${description}`;
    }
    return text;
  };

  public navigateToPatient = (patientId: Number) => {
    this.allowPatientNavigation$.subscribe((v) => {
      if (v) {
        this.router.navigate([`/patient/${patientId}`]);
      }
    });
  };

  public handleHospitalizationRiskChartSelection = (
    stratification: HospitalizationRiskStratification | null
  ) => {
    this.filtersService.formGroup.patchValue({
      hospitalizationRiskStratifications: stratification
        ? [stratification]
        : [],
    });
  };

  public handleLupaRiskChartSelection = (
    selection: LupaRiskChartSelection | null
  ) => {
    if (!selection) {
      this.filtersService.formGroup.patchValue({
        lupaVisitCompletionStatuses: [],
        episodeDay: null,
      });
    } else {
      this.filtersService.formGroup.patchValue({
        lupaVisitCompletionStatuses: [selection.completionStatus],
        episodeDay: selection.episodeDayRange,
      });
    }
  };

  public handleVisitUtilizationChartSelection = (
    level: VisitUtilizationChartSelection | null
  ) => {
    if (!level) {
      this.filtersService.formGroup.patchValue({
        visitUtilizationLevels: [],
        episodeDay: null,
      });
    } else {
      this.filtersService.formGroup.patchValue({
        visitUtilizationLevels: [level.level],
        episodeDay: level.episodeDayRange,
      });
    }
    this.reload();
  };

  async downloadCSV() {
    const filters = await firstValueFrom(this.filters$);
    this.hasCSVDownloaded = false;
    this.populationService
      .getPopulationViewCsv(filters)
      .pipe(first())
      .subscribe((resp) => {
        this.hasCSVDownloaded = true;
      });
  }

  private async updateDisplayedColumns() {
    const tableWidth = this.tableContainer?.nativeElement.clientWidth ?? 0;
    let consumedWidth = 0;
    const displayedColumns: PopulationViewColumn[] = [];
    for (const column of this.prioritizedColumns) {
      const visible = column.visible
        ? await firstValueFrom(column.visible)
        : true;
      if (visible && consumedWidth + column.minWidth <= tableWidth) {
        displayedColumns.push(column);
        consumedWidth += column.minWidth + COLUMN_SPACING;
      }
    }
    this.displayedColumns = displayedColumns
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((c) => c.name);
  }
}
