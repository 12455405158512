<div [id]="id" class="row no-gutters atlas-banner p-3">
  <div class="col-12 col-lg me-lg-auto mb-2 align-self-center">
    <!-- Image Slot -->
    <div class="d-flex">
      <div
        #bannerImage
        [ngClass]="{
          'banner-image align-self-center': bannerImage.children.length
        }"
      >
        <ng-content
          select="[atlas-banner-image], [atlasBannerImage]"
        ></ng-content>
      </div>

      <!-- Content -->
      <div class="banner-content align-self-center">
        <ng-content select="[atlas-banner-content]"></ng-content>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div class="col-12 text-right col-lg-auto align-self-center">
    <ng-content select="[atlas-banner-actions]"></ng-content>
  </div>
</div>
