import { Observable, Subject } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
import { AtlasBannerServiceInterface } from './banner-service-interface';

@Directive()
export class AtlasBannerRef<R = any> implements OnDestroy {
  /** Subject for notifying the user that the dialog has finished closing. */
  private afterClosed$ = new Subject<R | undefined>();

  constructor(private bannerService: AtlasBannerServiceInterface) {}

  /**
   * Close Banner
   * @param: optional parameter to pass a values
   */
  close(bannerCloseResult?): void {
    this.bannerService.detachBannerContent();
    this.afterClosed$.next(bannerCloseResult);
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   */
  get afterClosed(): Observable<R | undefined> {
    return this.afterClosed$.asObservable();
  }

  ngOnDestroy(): void {
    this.afterClosed$.complete();
  }
}
