<app-topnav class="navbar">
  <div app-topnav-logo>
    <div class="d-flex align-item-center">
      <div class="mt-1">
        <img src="assets/wellsky-logo.svg" width="160px" alt="WellSky Logo" />
      </div>
      <div class="app-title me-auto">CareInsights for Home Health</div>

      <div app-topnav-action>
        <nav mat-tab-nav-bar class="nav-bar" [tabPanel]="tabPanel">
          <a
            mat-tab-link
            routerLink="/population"
            [active]="isActive('/population')"
            [ngClass]="getTabIndicatorClass('/population')"
            *ngIf="hasUserRole"
          >
            Population
          </a>
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      </div>

      <div class="mt-1" *ngIf="userIsLoggedIn">
        <ng-container *ngIf="showAlertsWidget$ | async">
          <app-notification-widget
            data-test-id="navbar_notification_widget"
            alertManagerUrl="/alerts"
            [showAlertManagerLink]="true"
            [enableCache]="true"
            [enablePolling]="true"
            [showBadge]="true"
            (notificationSelected)="handleNotificationSelection($event)"
          >
          </app-notification-widget>
        </ng-container>

        <button
          [matMenuTriggerFor]="navbarMenu"
          mat-icon-button
          aria-label="Account Circle Icon Button"
        >
          <mat-icon>account_circle</mat-icon>
        </button>

        <mat-menu #navbarMenu="matMenu">
          <ng-container *ngIf="userIsLoggedIn">
            <ng-container *ngIf="hasAdminRole">
              <button mat-menu-item>
                <a [routerLink]="['/admin/users']">Manage users</a>
              </button>
              <button mat-menu-item>
                <a [routerLink]="['/admin/organization']"
                  >Manage organization</a
                >
              </button>
            </ng-container>
            <mat-divider></mat-divider>
            <button mat-menu-item>
              <div (click)="logout()">Sign out</div>
            </button>
          </ng-container>
          <ng-container *ngIf="!userIsLoggedIn">
            <button mat-menu-item>
              <div (click)="login()">Login</div>
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
</app-topnav>
