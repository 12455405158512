import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LastPipe } from './pipes/last.pipe';
import { EnableTooltipOnOverflowDirective } from './directives/enable-tooltip-onoverflow.directive';
import { OrganizationalUnitSelectorComponent } from './organizational-unit-selector/organizational-unit-selector.component';
import { OrganizationalUnitSearchComponent } from './forms/controls/organizational-unit-search/organizational-unit-search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { AtlasInputModule } from '../atlas-ui/input';
import { AtlasDialogModule } from '../atlas-ui/dialog';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ConfirmationDialogComponent,
    LastPipe,
    EnableTooltipOnOverflowDirective,
    OrganizationalUnitSelectorComponent,
    OrganizationalUnitSearchComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AtlasDialogModule,
    AtlasDialogModule,
    MatIconModule,
    ScrollingModule,
    AtlasInputModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatListModule,
    FormsModule,
    MatInputModule,
  ],
  exports: [
    BreadcrumbComponent,
    LastPipe,
    EnableTooltipOnOverflowDirective,
    OrganizationalUnitSearchComponent,
  ],
})
export class CoreModule {}
