<mat-form-field appearance="fill">
  <mat-select
    [formControl]="fc"
    [placeholder]="placeholderLabel"
    [multiple]="true"
    #itemSelect
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterControl"
        [placeholderLabel]="placeholderLabel"
        noEntriesFoundLabel="no matches found"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let item of this.filteredItems | async"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
