import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AtlasBannerService } from './banner.service';
import { Portal } from '@angular/cdk/portal';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subscription } from 'rxjs/internal/Subscription';

const bannerAnimation = trigger('bannerAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(300, style({ transform: 'translateY(0%)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate(250, style({ transform: 'translateY(-100%)', opacity: 0 })),
  ]),
]);

@Component({
  selector: 'atlas-banner-container',
  templateUrl: './banner-container.component.html',
  exportAs: 'atlasBannerContainer',
  animations: [bannerAnimation],
})
export class AtlasBannerContainer implements AfterViewInit, OnDestroy {
  portal: Portal<any> | null;
  private _getBannerPortalSubscription: Subscription;

  constructor(private bannerService: AtlasBannerService) {}

  ngAfterViewInit() {
    if (this._getBannerPortalSubscription) {
      this._getBannerPortalSubscription.unsubscribe();
    }

    // Subscribe to portal emitted by Banner service
    this._getBannerPortalSubscription = this.bannerService.getBannerPortal.subscribe(
      (componentInstance) => {
        this.portal = componentInstance;
      }
    );
  }

  ngOnDestroy() {
    if (this._getBannerPortalSubscription) {
      this._getBannerPortalSubscription.unsubscribe();
    }
  }
}
